/* 3d Party Modules */

const $ = require("jquery");
import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Classes */
import { WebApp } from './web_app.js';
const swiper = require('swiper');



$(window).on("load", () => (new WebApp()).onLoad());