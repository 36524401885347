import { View } from "../View/View";
import projectTmpl from "./project.tmpl";
import project_style from "./project.css";
const $ = require("jquery");
const _ = require("lodash");

export class Project extends View {
  constructor(selector) {
    super(selector, projectTmpl);
  }

  onLoad() {
    $("#main_style").append(project_style);
    this.render();
  }
}
