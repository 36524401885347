import footer_tmpl from "./footer.tmpl";
import footer_style from "./footer.css";
import { View } from "../View/View";
const $ = require("jquery");



export class Footer extends View {
    constructor(selector) {
        super(selector,footer_tmpl);
    }
    onLoad() {
        $('#footer_style').append(footer_style);
        this.render();
    }
}