import _ from 'lodash';
const $ = require("jquery");

export class View {
    constructor(selector, template) {
        this.selector = selector;
        this.template = _.template(template);
    }

    /**
     * 
     * @param {*} events : [ { selector, type, action }, ... ]
     */
    registerEvents(events) {
        this.events = events;
    }

    attachEvent(event) {
        $(event.selector).on(event.type, event.action);
    }

    attachEvents() {
        this.events.forEach(event => this.attachEvent(event));
    }

   
    render(renderData={}) {
        // step 1
        let html = this.template(renderData);
        // step 2 append html === inject in DOM by using selector
        // step 2.1 clean view of area
        // $(this.selector).empty();
        // step 2.2
        $(this.selector).append(html);    
        // step 3: 
        if (this.events)
            this.attachEvents();
    }
}