import { View } from "../View/View";
import foreisTmpl from "./foreis.tmpl"
import foreiStyle from "./foreis.css"
const $ = require("jquery");

export class Foreis extends View {
    constructor(selector) {
        super(selector,foreisTmpl);
    }
    onLoad() {
        this.render();
        $('#main_style').append(foreiStyle);
    }
}