import { Navbar } from './nav_bar/nav_bar.js'
import { Home } from './home/home.js';
import { Footer } from './footer/footer.js';


export class WebApp {

    onLoad() {
        var navbar = new Navbar('#header-container');
        navbar.onLoad();
        var home = new Home('#main-container');
        home.onLoad();
        var footer = new Footer('#footer-container');
        footer.onLoad();
    }
}

