import { View } from "../../View/View";
import ServiceCardTmpl from "./service_card.tmpl"
import  style_service from "./service_card.css"
import { Service } from "./service/service";
const $ = require("jquery");

var i = 0;
export class ServiceCard extends View {
    constructor(selector, service, i) {
        super(selector, ServiceCardTmpl);
        this.i = i;
        this.thematic_category = service.thematic_category
        this.title = service.title;
        this.developed_by = service.developed_by;
        this.image_path = "../../../images/services/"+service.image_path;
        this.link = service.link;
        this.id = "serv_card" + i;
        this.event_selector = "#serv_card" +i;
        this.registerEvents([
            { type: 'click', selector: this.event_selector, action: (evt) => this.onClick(evt) },
        ]);
        i++;
    }

    // Rendering Functionality
    onLoad() {
        this.render({
            title: this.title,
            developed_by: this.developed_by,
            id: this.id,
            image_path: this.image_path
        });
    }

    onClose() {}

    // Events
    onClick() {
        $('#main-container').empty();
        var tmp = new Service('#main-container',this.link, this.title, 0,this.image_path);
    }
}