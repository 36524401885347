import navbar_tmpl from "./nav_bar.tmpl";
const _ = require("lodash");
const $ = require("jquery");
import { View } from "../View/View";
import { BrowseServices } from "../browse_services/browse_services";
import { Home } from "../home/home";
import { Foreis } from "../foreis/foreis";
import { Project } from "../project/project";
import navbar_style from "./nav_bar.css";

export class Navbar extends View {
  constructor(selector) {
    super(selector, navbar_tmpl);
    this.registerEvents([
      {
        selector: ".navbar .container-fluid ul li",
        type: "click",
        action: (e) => this.change_link(e),
      },
      {
        selector: "#logo",
        type: "click",
        action: (e) => this.render_home(e),
      },
      {
        selector: "#home",
        type: "click",
        action: (e) => this.render_home(e),
      },
      {
        selector: "#foreis",
        type: "click",
        action: (e) => this.render_foreis(e),
      },
      {
        selector: "#servs",
        type: "click",
        action: (e) => this.render_services(e),
      },
      {
        selector: "#project",
        type: "click",
        action: (e) => this.render_project(e),
      },
    ]);
  }

  change_link(e) {
    e.preventDefault();
    $("#nav-title").empty();
    $(".navbar .container-fluid ul li.active").removeClass("active");
    $(e.currentTarget).addClass("active");
    $("#main_style").empty();
    $("#main-container").empty();
  }

  render_home(e) {
    var home = new Home("#main-container");
    home.onLoad();
  }
  render_foreis(e) {
    var foreis = new Foreis("#main-container");
    foreis.onLoad();
  }
  render_services(e) {
    var browse_services = new BrowseServices("#main-container");
    browse_services.onLoad();
  }

  render_project(e) {
    var project = new Project("#main-container");
    project.onLoad();
  }

  onLoad() {
    this.render();
  }
}
