import { View } from "../View/View";
import homeTmpl from "./home.tmpl"
import home_style from "./home.css"
const $ = require("jquery");
const _ = require("lodash");

export class Home extends View {
    constructor(selector) {
        super(selector,homeTmpl);
    }

    
    onLoad() {
        $('#main_style').append(home_style);
        this.render();
    }
}