import BrowseServiceTmpl from "./browse_services.tmpl";
const $ = require("jquery");
import {View} from "../View/View";
import services from "../../services.json";
import { ServiceCard } from "./service_card/service_card";
import service_card_style from "./service_card/service_card.css";


export class BrowseServices extends View {
    constructor(selector, services) {
        super(selector, BrowseServiceTmpl);
        this.services = services;
    }
    
    registerEvents() {
        super.registerEvents([
            {
                type, selector, action
            }
        ]);
    }
    
    

    createServiceCards(){

        var j = 0;
        services.service.forEach((tmp) => {
            var tmpCard = new ServiceCard("#services-list",tmp,j);
            j++;
            tmpCard.onLoad();
        });
    }
    
    

    onLoad() {
        // render template with data
        this.render();
        this.createServiceCards();
        $('main').append(service_card_style);
    }
}

