import { View } from "../../../View/View";
import ServiceTmpl from "./service.tmpl"
import service_style from "./service.css"
const $ = require("jquery");
// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

// init Swiper:
const swiper = new Swiper('.swiper');


export class Service extends View {
  constructor(selector, categories, mainTitle, i, logo_path) {
    super(selector, ServiceTmpl);
    this.render();
    var categ = require('../../../../services/' + categories);
    this.logo_path = "../" + logo_path;
    var logo_and_title = '<div class="container">\
                                  <div class="row ">\
                                      <div class="col-md-2"><img id="service_logo" src="'+ this.logo_path + '"></div>\
                                      <div id="main_t" class="col-md-10 "></div>\
                                  </div>\
                              </div>';

    var main_t = "<h2 id='ServiceMainTitle'>" + mainTitle + "</h2><ul id='menu'></ul>";
    var links = categ.links;

    $('#service').append(logo_and_title);
    $('#main_t').append(main_t);
    if (links) {
      links.forEach((tmp, index) => {
        let li = (index > 0 ? "|" : "") + "<li><a href='" + tmp.link + "' target='_blank'>" + tmp.title + "</a></li>"
        $('#menu').append(li);
      })
    }
    let events = [];
    console.log(links);
    if (categ.carousel) {
      $('#service').append('<div  class="swiper mySwiper">\
                  <div id="swiper" class="swiper-wrapper">\
                  </div>\
                  <div class="swiper-button-next"></div>\
                  <div class="swiper-button-prev"></div>\
                  <div class="swiper-pagination"></div>\
            </div>');
      $('#service').append('<div id="myModal" class="modal">\
               <img class="modal-content" id="img01">\
                <div id="caption"></div>');
      var j = 0;
      // When the user clicks on <span> (x), close the modal
      var modal = document.getElementById('myModal');
      modal.onclick = function () {
        img01.className += " out";
        setTimeout(function () {
          modal.style.display = "none";
          img01.className = "modal-content";
        }, 400);

      }
      categ.carousel.forEach((tmp) => {
        var img = '<div class="swiper-slide">\
                              <img id="carousImage-'+ j + '" class="imageportrait" src="../../../../images/services/' + tmp.image + '" alt="Card image cap" >\
                              <div id="myModal'+ j + '" class="modal">\
                                <img class="modal-content" id="imgc_'+ j + '">\
                              </div>\
                          </div>';





        $('#swiper').append(img);

        // Get the image and insert it inside the modal - use its "alt" text as a caption
        var imgmodal = document.getElementById("carousImage-" + j);
        var modalImg = document.getElementById("img01");
        imgmodal.onclick = function () {
          modal.style.display = "block";
          modalImg.src = this.src;
          modalImg.alt = this.alt;
        }
        j++;
      });
      var swiper = new Swiper(".mySwiper", {
        slidesPerView: 3,
        spaceBetween: 30,
        slidesPerGroup: 3,
        loop: false,
        loopFillGroupWithBlank: true,
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
    categ.categories.forEach((tmp) => {
      var title = "<h3 id='serv_title_" + i + "' class='serviceTitle'>" + tmp.title + "</h3>";
      $('#service').append(title);
      if (tmp.content.type == 'txt') {
        var content = "<div class='serv_title_" + i + "'><hr><p>" + tmp.content.data + "</p><hr class='serviceLine'>";

      }
      else {
        var content = "<div class='serv_title_" + i + "'>" + tmp.content.data; +"</div>";
      }
      events.push({
        selector: '#serv_title_' + i,
        type: 'click',
        action: (e) => {
          var m = e.target.id;
          console.log(m);
          $('.' + m).toggle()
        }
      });

      $('#service').append(content);
      $(".serv_title_" + i).hide();
      i++;
    });
    $(".serv_title_" + 0).show();
    this.registerEvents(events);
    this.attachEvents();
  }
}